import { Component } from '@angular/core';
import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import EmailVerification from 'supertokens-web-js/recipe/emailverification';
import { environment } from 'src/environments/environment';
import { RouterOutlet } from '@angular/router';

SuperTokens.init({
    appInfo: {
        apiDomain: environment.urlSupertoken,
        // apiBasePath: "/auth",
        apiBasePath: "/superapi/auth",
        appName: "ITCAuthSupertoken"
    },
    recipeList: [
        Session.init(),
        EmailPassword.init(),
        EmailVerification.init(),
    ],
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {
  title = 'app-autenticador-itc';
}
